import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import GoogleReCaptcha from "react-google-recaptcha";
import './style.scss';
import { configEnv } from '../../config'

export const Demo: React.FC = () => {
  const storeCategories = [
    { ES: 'Moda', EN: 'Fashion' },
    { ES: 'Mercado', EN: 'Grocery' },
    { ES: 'Tecnología', EN: 'Electronics' }
  ]

  const countryCodes = ['CO'];

  /*   useEffect(() => { //There is a problem with CORS!
      const geolocation = async () => {
        const result = await axios
          .get('http://geoplugin.net/json.gp')
          .then(({ data }) => data.geoplugin_countryCode);
  
        localStorage.setItem('geolocation', result);
      };
      geolocation();
  
      return () => {
        geolocation();
      };
    }, []);
   */
  const [inputs, setInputs] = useState({
    userFullname: '',
    storeCategory: storeCategories[0].EN, // ToDo: Bring from backend
  });

  const {
    userFullname,
    storeCategory
  } = inputs

  const [country, setCountry] = useState('');
  const [userPhone, setUserPhone] = useState<any>('.');
  const [countryCode, setCountryCode] = useState<any>();
  const [captcha, setCaptcha] = useState<any>();
  const [email, setEmail] = useState<any>("");
  const [emailError, setEmailError] = useState<any>(false);

  useEffect(() => {
    setCountryCode(country)
    setUserPhone('')
  }, [country])

  const isValidEmail = (event: any) => {
    setEmailError(false)
    const regEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]{2,}\.[A-Za-z]{2,}$/;
    if (regEmail.test(event.target.value)) setEmail(event.target.value)
    else setEmailError(true)
  }

  const handleCaptcha = (token: string) => {
    setCaptcha(token)
  }

  function handleChange(e: any) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e: any) {
    if (!captcha) alert('Por favor valida el captcha.')
    else {
      if (!userFullname || emailError || !storeCategory || !country || !isValidPhoneNumber(userPhone)) alert('Revisa la información.')
      else {
        try {
          await axios.post(configEnv?.API_URL + '/slack/message', {
            code: 'new_demo',
            userFullname,
            userEmail: email,
            userPhone,
            storeCategory,
            country
          })
          alert('Información enviada con éxito!')
        } catch (error) {
          alert('Hubo un error. Verifica la información suministrada e intenta más tarde.')
        }
      }
    }
  }

  return (
    <div className="demo-container p-2">
      <div className="left-content">
        <h2>Contacta a un representante de ventas<span style={{ color: "#ff70a0" }}>.</span></h2> <br />
        <div>
          <p>
            Agenda una llamada con uno de nuestros profesionales y descúbre cómo
            podemos ayudarte a alcanzar tus objetivos de ventas y a posicionar
            tu marca a través de un canal eCommerce.
          </p>
          <span>En tu demo descubrirás como:</span>
          <ul className='demo-featues'>
            <li>
              Crear un eCommerce en cuestión de minutos.
            </li>
            <li>
              Visualizar, registrar y gestionar órdenes de compra.
            </li>
            <li>
              Recuperar carritos abandonados y personalizar anuncios en tu sitio.
            </li>
            <li>
              Sincronizar tu eCommerce con un canal de Whatsapp para enviar notificaciones e información relevante.
            </li>
            <li>
              Analizar a clientes y clasificarlos con base en su comportamiento de compra.
            </li>
            <li>
              Aumentar el nivel de satifaccion de clientes y encontrar oportunidas de mejora continua.
            </li>
            <li>
              Y mucho más!
            </li>
          </ul>
          {/*           <p>
            Tambien puedes suscribirte a nuestro newsletter para recibir
            informacion sobre noticias, nuevas funcionalidades, webinar y mucho
            mas.
          </p> */}
        </div>
        {/*         <div className='d-flex email-r-e '>
          <input
            name="email"
            id="email"
            placeholder='correo electronico'
            type="email"
          />
          <button className='btn btn-primaryBlue btn-block ml-3 btn-sent-r-e'>Enviar</button>
        </div> */}
      </div>
      <div className="right-content">
        <div className="rec-register">
          <div className="d-flex flex-column">
            <div className="input-group-container">
              <label htmlFor="email" className="input-label">
                Nombre y Apellido*
              </label>
              <input
                className="box-input"
                name="userFullname"
                id="userFullname"
                type="text"
                required={true}
                onChange={handleChange}
              />
            </div>

            <div className="input-group-container">
              <label htmlFor="email" className="input-label">
                Correo electronico*
              </label>
              <div className='d-flex flex-column'>
                <input
                  className="box-input"
                  name="userEmail"
                  id="userEmail"
                  type="email"
                  onChange={isValidEmail}
                />
                <span className={emailError ? '' : 'd-none'} style={{ color: 'red', fontSize: '10px' }}>Email no válido</span>
              </div>
            </div>

            <div className="input-group-container">
              <label htmlFor="email" className="input-label">
                Tipo de tienda*
              </label>
              <select className="box-input" defaultValue="0" required lang='ES' name="storeCategory" id="storeCategory" value={storeCategory} onChange={handleChange}>
                {storeCategories.map((cat, k) => <option key={k} value={cat.EN}>{cat.ES}</option>)}
              </select>
            </div>

            <div className="input-group-container">
              <label htmlFor="country" className="input-label">
                Pais*
              </label>
              <ReactFlagsSelect
                countries={countryCodes}
                className="box-input"
                selected={country}
                onSelect={code => setCountry(code)}
                placeholder="Seleccione el pais"
              />
            </div>

            <div className="input-group-container">
              <label htmlFor="phone" className="input-label">
                Telefono*
              </label>
              <div className='phone-container'>
                <PhoneInput
                  withCountryCallingCode
                  country={countryCode}
                  international
                  value={userPhone}
                  onChange={setUserPhone}
                />
                <span className={userPhone && isValidPhoneNumber(userPhone) ? 'd-none' : ''} style={{ color: 'red', fontSize: '10px' }}>Teléfono no válido</span>
              </div>
            </div>
            <div className="captcha-container"
            >
              <GoogleReCaptcha
                sitekey={configEnv?.CAPTCHA_KEY || ''}
                onChange={(token) => handleCaptcha(token || '')}
              />
            </div>
          </div>
          <div className="login-actions ">
            <button
              type="submit"
              className="btn btn-primart-enterpise btn-block"
              onClick={handleSubmit}
            >
              Solicitar demostración
            </button>
          </div>
        </div>
      </div >
    </div >
  );
};
